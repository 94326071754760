@import '~@devsta/ui-kit/scss/core/vars';

.workspaces {
  display: flex;
  justify-content: center;

  &__container {
    margin-top: 90px;
    max-width: 900px;
    padding: 0 20px;
    width: 100%;
  }

  &-item {
    align-items: center;
    background-color: $white;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: $shadows-soft;
    display: flex;
    height: 100px;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;

    &__info {
      align-items: center;
      display: flex;
      text-align: start;
      width: 80%;

      &-name {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width: 75%;
      }

      .image {
        min-width: inherit;
      }
    }

    .launch {
      @media only screen and (max-width: $media-sizes-medium) {
        display: none;
      }
    }

    .return {
      display: none;
      transform: rotate(180deg);

      &:hover {
        cursor: pointer;
      }

      @media only screen and (max-width: $media-sizes-medium) {
        display: block;
      }
    }

    g {
      stroke: none;
    }
  }

  &__sub-title {
    margin: 40px 0 20px 0;
  }
}
