@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';
@import '../mixins/login-form-mixin';

.login-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;

  &__body {
    @include login-form-mixin;

    align-items: flex-start;
    
    > .login-header {
      text-align: left;
      
      > .icon-container {
        justify-content: flex-start;
      }
    }
    
    &__workspace {
      .input-container {
        width: 100%;

        &__decorator--is-suffix {
          flex: 1;
          justify-content: left;
          overflow: hidden;
          user-select: none;
        }

        &__decorator--is-suffix button {
          height: 100%;
        }

        &__decorator--is-suffix p {
          line-height: 40px;
          margin: 0;
        }
      }
    }


    &__account {
      font-size: $font-sizes-default;
      margin-bottom: 0;
      margin-top: 40px;
      text-align: left;
    }

    &__form {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;
      position: relative;
      width: 100%;

      @media only screen and (max-width: $media-sizes-small) {
        align-items: center;
      }
    }

    &__forgot-btn {
      margin-top: 10px;
      max-width: 380px;
      text-align: right;
      width: 100%;
    }

    &__help-link {
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__counter {
      color: $cerulean;
      line-height: 22px;
    }

    &__error {
      align-items: start;
      background-color: rgba(208, 30, 75, 0.1);
      border: 1px solid #d01e4b;
      border-radius: 3px;
      display: flex;
      margin-top: 40px;
      max-width: 380px;
      width: 100%;

      > .icon-container {
        margin-left: 1em;
        margin-top: 1.1em;
      }

      > p {
        font-size: $font-sizes-default;
        line-height: 22px;
        margin-left: 10px;
        margin-right: 1em;
        text-align: left;
      }
    }

    &__success {
      align-items: center;
      background-color: rgba(39, 187, 161, 0.3);
      border: 1px solid $cerulean;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      height: 55px;
      margin-bottom: 40px;
      padding: 0 1em;
      width: 100%;

      @media only screen and (max-width: $media-sizes-medium) {
        display: flex;
        flex-direction: column;
        height: 80px;
        justify-content: center;
        margin-bottom: 30px !important;
        margin-top: 50px !important;

        > .icon-container {
          display: none;
        }
      }

      > .icon-container {
        margin-right: 1em;
      }

      .success-text {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;

        @media only screen and (max-width: $media-sizes-medium) {
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        a {
          display: flex;
          flex-shrink: 0;
          text-decoration: none;
        }
      }
    }
  }

  &__code {
    margin-bottom: 20px;

    margin-top: 20px;
    width: unset !important;

    input {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      font-family: sans-serif !important;
      font-size: 250% !important;
      font-weight: bold;
      text-transform: uppercase;

      &:first-child,
      &:nth-child(4) {
        border-bottom-left-radius: 5px !important;
        border-top-left-radius: 5px !important;
      }

      &:last-child,
      &:nth-child(3) {
        border-bottom-right-radius: 5px !important;
        border-top-right-radius: 5px !important;
      }

      &:nth-child(3) {
        border-right: solid 1px #a8adb7;
        margin-right: 15px !important;
      }

      &:focus {
        border: solid 1px #a8adb7 !important;
      }
    }
  }

  &__footer {
    box-sizing: border-box;
    display: flex;
    margin-bottom: 10px;
    margin-top: auto;
    width: 100%;

    > p {
      font-size: 15px;
      margin: 0;
      margin-right: 10px;
    }
  }
}
