@import '~@devsta/ui-kit/scss/core/vars';

.core-layout {
  display: grid;
  flex: 1;
  grid-template-columns: minmax(min-content, max-content) minmax(0, auto);
  grid-template-rows: 64px auto;
  grid-template-areas:
    "header header"
    "sidebar content";
  // This is to enable infinite scrolling on lists inside apps - DO NOT REMOVE
  // If your app doesn't scroll, it needs overflow: auto at the top level
  overflow-y: hidden;

  > .drawer {
    grid-area: sidebar;
    position: relative;
    z-index: 2;
  }

  &__header {
    grid-area: header;
    position: fixed;
    z-index: 3;

    .menu-item {
      &__separator {
        width: auto !important;
        border-bottom: 1px solid $dusty !important;
      }
    }
  }

  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    // This is to enable infinite scrolling on lists inside apps - DO NOT REMOVE
    // If your app doesn't scroll, it needs overflow: auto at the top level
    overflow-y: hidden;
  }

  &__no-scroll {
    overflow: hidden;
  }
}

@media screen and (max-width: $media-sizes-small) {
  .core-layout {
    grid-template-columns: minmax(0, auto);
    grid-template-areas:
    "header header"
    "content content";
  }
}
