@import '~@devsta/ui-kit/scss/core/vars';
@import '../mixins/login-form-mixin';

.forgot-password {
  @include login-form-mixin;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__field:first-of-type {
    margin-top: 40px;
  }

  @media screen and (max-width: $media-sizes-small) {
    &__container {
      .forgot-password {
        &__field:first-of-type {
          margin-top: 20px;
        }
      }
    }
  }
}
